import React from 'react';
import './AppSpacer.scss';

type SpacerProps = {
    readonly height: number,
}

function AppSpacer({ height }: SpacerProps) {
    return (
        <div className={`app-spacer-${height}`} >
        </div>
    );
}

export default AppSpacer;

