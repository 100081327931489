// Dev Logs from October 2022
import PageData from '../../PageData';

const october2022DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from October 28th 2022.',
        path: '/dev-log/2022-10-28',
        title: 'Dev Log',
        text: `Week of October 28th 2022: Switching weekly updates to Fridays, got this website mostly working with placeholders where
        there was currently no information. Continued setting up Rock Bottom Games L.L.C.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our special update from October 24th 2022.',
        path: '/wedding-log/2022-10-24',
        title: 'Wedding Log',
        text: `Monday October 24th 2022: Special update! My wife and I got married at the courthouse and met up with friends afterwards!`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from October 22nd 2022.',
        path: '/dev-log/2022-10-22',
        title: 'Dev Log',
        text: `Week of October 22nd 2022: This week, I created initial unity project and began coding!
        Also began work on this website, started opening social media accounts, made logos and art for
        everything. I also got my first Patreon member!`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our initial update from October 15th 2022.',
        path: '/dev-log/2022-10-15',
        title: 'Dev Log',
        text: `Saturday October 15th 2022: Today was my first day after leaving Google, it's a Saturday, 
        but I'm so excited to work that I got started. Today I focused on setting up my website,
        applying for an L.L.C..`,
        links: [],
    },
];

export default october2022DevLogPages;