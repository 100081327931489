import React from 'react';
import AppSpacer from '../../Components/AppSpacer/AppSpacer';
import AppLinks, { LinkStyle } from '../../Components/Links/AppLinks/AppLinks';
import Title from '../../Components/Title/Title';
import { homePageLinks } from '../../Shared/Data/LinkData';
import './Home.scss';

function Home() {
    return (
        <div className="home-page">
            <AppSpacer height={1} />
            <div className="app-title">
                <Title></Title>
            </div>
            <AppSpacer height={2} />
            <AppLinks
                links={homePageLinks}
                linkStyle={LinkStyle.Dirt}
            />
        </div>
    );
}

export default Home;
