import React from 'react';
import LinkData from '../../../Shared/Data/LinkData';
import DirtLink from '../../../Components/Links/DirtLink/DirtLink';
import MetalLink from '../../../Components/Links/MetalLink/MetalLink';
import './AppLink.scss';

enum LinkStyle {
    Dirt = 1,
    Metal,
}

type AppLinkProps = {
    readonly linkStyle: LinkStyle,
    readonly linkData: LinkData,
    readonly className?: string,
}

function StyledLink({ linkStyle, linkData, className = '' }: AppLinkProps) {
    const fullClassName = `${className} ${linkData.className}`;

    switch (linkStyle) {
        case LinkStyle.Metal:
            return (
                <MetalLink
                    {...linkData}
                    className={fullClassName}
                />
            )
        default:
            return (
                <DirtLink
                    {...linkData}
                    className={fullClassName}
                />
            )
    }
}

function AppLink(appLinkProps: AppLinkProps) {
    return (
        <div className={`app-link ${appLinkProps.linkData.containerClassName}`}>
            <StyledLink {...appLinkProps} />
        </div>
    )
}

export default AppLink;
export {
    LinkStyle,
}