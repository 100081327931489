import React from 'react';
import AppSpacer from '../../Components/AppSpacer/AppSpacer';
import AppLinks, { LinkStyle } from '../../Components/Links/AppLinks/AppLinks';
import { homeLink } from '../../Shared/Data/LinkData';
import { useRouteError } from 'react-router-dom';
import './NotFound.scss';
import notFound from './Images/NotFound.png';

function App() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="not-found-root app-root">
            <div className="app">
                <AppSpacer height={1} />
                <div className="app-title">
                    <header className="title">
                        <img src={notFound} alt="Not Found" />
                    </header>
                </div>
                <AppSpacer height={1} />
                <AppLinks
                    isShort={true}
                    links={[homeLink]}
                    linkStyle={LinkStyle.Metal}
                />
            </div>
        </div>
    );
}

export default App;
