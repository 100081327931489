import LinkData from './LinkData';

// *********************** YouTube Videos ************************** //

const initialMatchTypes001: LinkData = {
    to: 'https://www.youtube.com/watch?v=s2Glf9IJ6J0',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'An initial look at Rogue Match Up\'s match types',
    label: 'Video',
}

const firstBoxy002: LinkData = {
    to: 'https://www.youtube.com/watch?v=GG-n66RtEvo',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'An initial look at Boxy pixel art',
    label: 'Video',
}


// ************************ Random Links *************************** //

const checklist: LinkData = {
    to: 'https://jtblanche.github.io/checklist/',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'A quick side project I made to help manage tasks for myself',
    label: 'Checklist',
}

const palettePicker: LinkData = {
    to: 'https://jtblanche.github.io/palettepicker/',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'A tool to help create color palettes',
    label: 'Palette Picker',
}

const taylorTheTechTutor: LinkData = {
    to: 'https://taylorthe.tech/tutor',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'Information regarding my videos for learning programming',
    label: 'Taylor the Tech Tutor',
}

// *********************** External Links ************************** //

const facebookLink: LinkData = {
    to: 'https://twitter.com/RockBottomG_LLC',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'Visit our Facebook page',
    label: 'Facebook',
}

const patreonLink: LinkData = {
    to: 'https://patreon.com/rockbottomgames?utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'Visit our Patreon page',
    label: 'Patreon',
}

const twitterLink: LinkData = {
    to: 'https://twitter.com/RockBottomG_LLC',
    className: '',
    target: '_blank',
    rel: 'noopener noreferrer',
    ariaLabel: 'Visit our Twitter page',
    label: 'Twitter',
}

// ************************* Link Lists **************************** //

const allExternalLinks: Array<LinkData> = [patreonLink, facebookLink, twitterLink];
const socialMediaLinks: Array<LinkData> = [twitterLink, facebookLink];
const supportUsLinks: Array<LinkData> = [taylorTheTechTutor, patreonLink];
const videos: Array<LinkData> = [initialMatchTypes001, firstBoxy002];

export default allExternalLinks;
export {
    // *********************** Youtube Videos ************************** //
    initialMatchTypes001,
    firstBoxy002,

    // ************************ Random Links *************************** //
    checklist,
    palettePicker,
    taylorTheTechTutor,

    // *********************** External Links ************************** //
    facebookLink,
    patreonLink,
    twitterLink,

    // ************************* Link Lists **************************** //
    socialMediaLinks,
    supportUsLinks,
    videos,
};