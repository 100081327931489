import React from 'react';
import {
    RouteObject
} from 'react-router-dom';
import PageData, {
    aboutUsPages,
    devLogPages,
    homePage,
    socialMediaPage,
    supportUsPage,
} from '../PageData';
import Home from '../../../Routes/Home/Home';
import InfoPanelPage from '../../../Components/Pages/InfoPanelPage/InfoPanelPage'

function getLinkDataFromPageData(pageData: PageData, element: React.ReactNode): RouteObject {
    return {
        index: pageData.path === '/',
        path: pageData.path === '/' ? undefined : pageData.path,
        name: pageData.title,
        element: element,
    } as RouteObject;
}

const routes: Array<RouteObject> = [
    getLinkDataFromPageData(homePage, <Home />),
    ...aboutUsPages.map(aboutUsPage => getLinkDataFromPageData(aboutUsPage, <InfoPanelPage {...aboutUsPage} />)),
    ...devLogPages.map(devLogPage => getLinkDataFromPageData(devLogPage, <InfoPanelPage {...devLogPage} />)),
    getLinkDataFromPageData(socialMediaPage, <InfoPanelPage {...socialMediaPage} />),
    getLinkDataFromPageData(supportUsPage, <InfoPanelPage {...supportUsPage} />),
]

export default routes;