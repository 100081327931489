// Dev Logs from March 2023
import { firstBoxy002 } from '../../../LinkData/ExternalLinks';
import PageData from '../../PageData';

const march2023DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from March 31st 2023.',
        path: '/dev-log/2023-03-31',
        title: 'Dev Log',
        text: `Week of March 31st 2023: Started modeling Boo Leon in Blender and learning about cel shading and cloth physics in Blender.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from March 24th 2023.',
        path: '/dev-log/2023-03-24',
        title: 'Dev Log',
        text: `Week of March 24th 2023: Started learning about armature/bones in blender to help animate Boxy in multiple dimensions.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from March 17th 2023.',
        path: '/dev-log/2023-03-17',
        title: 'Dev Log',
        text: `Week of March 17th 2023: Used the blender model to help make pixel art of different angles for Boxy.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from March 10th 2023.',
        path: '/dev-log/2023-03-10',
        title: 'Dev Log',
        text: `Week of March 10th 2023: Started pixel art for "Boo Leon". Started pixel art for different directions for boxy and started modeling boxy in Blender.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from March 3rd 2023.',
        path: '/dev-log/2023-03-03',
        title: 'Dev Log',
        text: `Week of March 3rd 2023: Decided to practice art in preparation for tutoring and to help with my game art.
        Began creating "Boxy" pixel art and animations. Also put animations into empty Unity app to see how it looks.`,
        links: [firstBoxy002],
    },
];

export default march2023DevLogPages;