import LinkData from './LinkData';
import PageData, {
    aboutUsPages,
    devLogPages,
    homePage,
    socialMediaPage,
    supportUsPage,
} from '../PageData';

// *********************** Internal Links ************************** //

function getInternalLinkDataFromPageData(pageData: PageData, containerClassName: string | undefined = undefined, overrideLabel: string | undefined = undefined): LinkData {
    return {
        ariaLabel: pageData.description,
        className: pageData.className,
        containerClassName: containerClassName,
        label: pageData.title,
        to: pageData.path,
        overrideLabel: overrideLabel
    } as LinkData;
}

const aboutUsLink: LinkData = getInternalLinkDataFromPageData(aboutUsPages[0]);
const devLogLink: LinkData = getInternalLinkDataFromPageData(devLogPages[0]);
const homeLink: LinkData = getInternalLinkDataFromPageData(homePage);
const socialMediaLink: LinkData = getInternalLinkDataFromPageData(socialMediaPage);
const supportUsLink: LinkData = getInternalLinkDataFromPageData(supportUsPage);

// ************************* Link Lists **************************** //

const allInternalLinks: Array<LinkData> = [aboutUsLink, devLogLink, homeLink, socialMediaLink, supportUsLink];
const homePageLinks: Array<LinkData> = [aboutUsLink, devLogLink, socialMediaLink, supportUsLink];

export default allInternalLinks;
export {
    // *********************** Internal Links ************************** //
    aboutUsLink,
    devLogLink,
    homeLink,
    socialMediaLink,
    supportUsLink,
    getInternalLinkDataFromPageData,

    // ************************* Link Lists **************************** //
    homePageLinks,
};