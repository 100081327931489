import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#9dd3d5',
            contrastText: '#201919',
        },
        secondary: {
            main: '#b8b9ff',
        },
        background: {
            default: '#222222',
            paper: '#222222',
        },
        error: {
            main: '#ff8a8a',
        },
        info: {
            main: '#66cfff',
        },
        success: {
            main: '#80d684',
        },
    },
});

export default theme;