// Dev Logs from 2022
import december2022DevLogPages from "./Log12";
import november2022DevLogPages from "./Log11";
import october2022DevLogPages from "./Log10";
import PageData from '../../PageData';

const devLogPages2022: PageData[] = [
    ...december2022DevLogPages,
    ...november2022DevLogPages,
    ...october2022DevLogPages,
];

export default devLogPages2022;