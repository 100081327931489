import React, { useState } from 'react';
import {
    Link,
} from 'react-router-dom';
import LinkData from '../../../Shared/Data/LinkData';
import { useLocation, Location } from "react-router-dom";
import './CustomLink.scss';

type LinkTextProps = {
    readonly label: string,
}

function LinkText({ label }: LinkTextProps) {
    return (
        <span>
            {label}
        </span>
    )
}

type LinkStateData = {
    readonly lastPage: Location
}

function CustomLink({ ariaLabel, label, overrideLabel, className, to, target, rel }: LinkData) {
    let lastPage = useLocation();

    const data: LinkStateData = {
        lastPage
    };

    const [classNames, setClassNames] = useState(`custom-link ${className}`);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            setClassNames(`custom-link ${className} active-link`);
        }
    };

    // 👇️ type event as React.KeyboardEvent<HTMLElement>
    const handleKeyUp = (_: React.KeyboardEvent<HTMLElement>) => {
        setClassNames(`${className}`);
    };

    return <div className="custom-link-container">
        {to.startsWith('http') ?
            <a
                className={classNames}
                href={to}
                target={target}
                rel={rel}
                aria-label={ariaLabel}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}>
                <LinkText label={overrideLabel ?? label} />
            </a>
            : (
                <Link
                    className={classNames}
                    target={target}
                    state={data}
                    rel={rel}
                    to={to}
                    aria-label={ariaLabel}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}>
                    <LinkText label={overrideLabel ?? label} />
                </Link>
            )
        }
    </div>;
}

export default CustomLink;
export type {
    LinkStateData,
}
