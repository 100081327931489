import aboutOurBusinesses from "./OurBusiness";
import aboutAllOurEmployees from "./OurEmployees";

const aboutUsPages = [
    ...aboutOurBusinesses,
    ...aboutAllOurEmployees,
];

// finalize links between pages:
for (let i = 0; i < aboutUsPages.length - 3; i++) {
    aboutUsPages[i].prev = (i > 0) ? aboutUsPages[i - 1] : undefined;
    aboutUsPages[i].next = (i < aboutUsPages.length - 1) ? aboutUsPages[i + 1] : undefined;
}

export default aboutUsPages;