import React from 'react';
import Link from '../CustomLink/CustomLink';
import LinkData from '../../../Shared/Data/LinkData';
import '../../../Shared/Styles/Dirt/Dirt.scss';
import styles from './DirtLink.module.scss';

function DirtLink(linkData: LinkData) {
    return (
        <Link
            {...linkData}
            className={`${styles.dirtLink} ${linkData.className}`}
        />
    );
}

export default DirtLink;
