import PageData from './PageData';
import { socialMediaLinks, supportUsLinks } from '../LinkData/ExternalLinks';

// **************************** Pages ****************************** //

const homePage: PageData = {
    className: '', // Override this in props
    description: 'Return to the home page',
    path: '/',
    title: 'Home',
    links: [],
};

const socialMediaPage: PageData = {
    className: '', // Override this in props
    description: 'Find Rock Bottom Games L.L.C. on all your favorite social media websites',
    path: '/social-media',
    title: 'Social',
    links: socialMediaLinks,

};

const supportUsPage: PageData = {
    className: '', // Override this in props
    description: 'Links to different ways you can support Rock Bottom Games L.L.C.',
    path: '/support-us',
    title: 'Support Us',
    links: supportUsLinks,
};

export {
    homePage,
    socialMediaPage,
    supportUsPage,
};