// Dev Logs from January 2023
import { taylorTheTechTutor } from '../../../LinkData/ExternalLinks';
import PageData from '../../PageData';

const january2023DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from January 27th 2023.',
        path: '/tutor-log/2023-01-27',
        title: 'Tutor Log',
        text: `Week of January 27th 2023: My goal while making the game has always been to also start tutoring in programming,
        so I started initial work on Taylor the Tech Tutor's website`,
        links: [taylorTheTechTutor],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from January 20th 2023.',
        path: '/wedding-log/2023-01-20',
        title: 'Wedding Log',
        text: `Week of January 20th 2023: Continued to focus work on getting ready for the wedding.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our special update from January 13th 2023.',
        path: '/wedding-log/2023-01-13',
        title: 'Wedding Log',
        text: `Week of January 13th 2023: Started work on the house to get it ready for our wedding celebration.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from January 6th 2023.',
        path: '/dev-log/2023-01-06',
        title: 'Dev Log',
        text: `Week of January 6th 2023: Spent this week learning and incorporating controller support with Unity's
        input system.  I also started setting up Steamworks account to work with Steam's control system.`,
        links: [],
    },
];

export default january2023DevLogPages;