import React, { useRef } from 'react';
import {
    useLocation,
    useOutlet,
} from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Footer from '../Components/Footer';
import './App.scss';

function App() {
    const location = useLocation()
    const currentOutlet = useOutlet()
    const nodeRef = useRef<HTMLDivElement>(null)

    return (
        <div className="app-root">
            <div className="app">
                <SwitchTransition>
                    <CSSTransition
                        key={location.pathname}
                        nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
                        timeout={300}
                        classNames="page"
                        unmountOnExit>
                        <div
                            ref={nodeRef as React.RefObject<HTMLDivElement>}
                            className="page">
                            {currentOutlet}
                        </div>
                    </CSSTransition>
                </SwitchTransition>

                <Footer />
            </div>
        </div>
    );
}

export default App;
