// Dev Logs from 2023
import april2023DevLogPages from "./Log04";
import march2023DevLogPages from "./Log03";
import february2023DevLogPages from "./Log02";
import january2023DevLogPages from "./Log01";
import PageData from '../../PageData';

const devLogPages2023: PageData[] = [
    ...april2023DevLogPages,
    ...march2023DevLogPages,
    ...february2023DevLogPages,
    ...january2023DevLogPages,
];

export default devLogPages2023;