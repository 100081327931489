// Dev Logs 
import devLogPages2023 from "./2023";
import devLogPages2022 from "./2022";
import PageData from '../PageData';

const devLogPages: PageData[] = [
    ...devLogPages2023,
    ...devLogPages2022,
];

// finalize links between pages:
for (let i = 0; i < devLogPages.length; i++) {
    devLogPages[i].prev = (i > 0) ? devLogPages[i - 1] : undefined;
    devLogPages[i].next = (i < devLogPages.length - 1) ? devLogPages[i + 1] : undefined;
}

export default devLogPages;