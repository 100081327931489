import PageData from '../PageData';
import { taylorTheTechTutor } from '../../LinkData/ExternalLinks';

const aboutOurBusiness: PageData = {
    className: '', // Override this in props
    description: `About our business, Rock Bottom Games L.L.C.`,
    text: `I have created Rock Bottom Games L.L.C. for the main purpose of creating fun games
    that I myself would spend hours playing as well.`,
    path: '/about-us',
    title: 'About Us',
    links: [],
};

const aboutOurLiveStreaming: PageData = {
    className: '', // Override this in props
    description: `About our live streaming services, Taylor the Tech Tutor`,
    text: `I am also in the process of building a live streaming service for learning programming
    through game development. More info available at taylorthetechtutor.com`,
    path: '/about-us/taylor-the-tech-tutor',
    title: 'Tutoring',
    links: [taylorTheTechTutor],
};

const aboutOurBusinesses: PageData[] = [
    aboutOurBusiness,
    aboutOurLiveStreaming
]

export default aboutOurBusinesses;
export {
    aboutOurBusiness,
    aboutOurLiveStreaming,
}