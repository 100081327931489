// Dev Logs from December 2022
import { palettePicker } from '../../../LinkData/ExternalLinks';
import PageData from '../../PageData';

const december2022DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from December 30th 2022.',
        path: '/dev-log/2022-12-30',
        title: 'Dev Log',
        text: `Week of December 30th 2022: Continued work on goat warrior pixel art. Kept it simple for holidays.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from December 23rd 2022.',
        path: '/dev-log/2022-12-23',
        title: 'Dev Log',
        text: `Week of December 23rd 2022: Learned about animations and made a goat warrior animation.
        Updated lightning art that connects runes in one of the match 3 types.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from December 16th 2022.',
        path: '/dev-log/2022-12-16',
        title: 'Dev Log',
        text: `Week of December 16th 2022: Added some backgrounds that change when the current character updates.
        Made updates to my palettePicker to help me quickly update rune colors.`,
        links: [palettePicker],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from December 9th 2022.',
        path: '/dev-log/2022-12-09',
        title: 'Dev Log',
        text: `Week of December 9th 2022: Continued code clean up (initial code was from following a tutorial)
        and learned ways to clean up Unity code.  Always improve! Also made a tool to help me create color palettes.`,
        links: [palettePicker],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from December 2nd 2022.',
        path: '/dev-log/2022-12-02',
        title: 'Dev Log',
        text: `Week of December 2nd 2022: Revamped the rune art so that it is controlled by some simple
        attributes that can be changed from the code. Read a lot about colors.`,
        links: [],
    },
];

export default december2022DevLogPages;