import React from 'react';
import AppLink, { LinkStyle } from '../AppLink/AppLink';
import LinkData from '../../../Shared/Data/LinkData';
import './AppLinks.scss';

type AppLinksProps = {
    readonly isShort?: boolean,
    readonly isPagination?: boolean,
    readonly linkStyle: LinkStyle,
    readonly links: Array<LinkData>
}

function AppLinks({ isShort = false, isPagination = false, links, linkStyle }: AppLinksProps) {
    return (
        <div className={`${isShort ? 'app-links-short' : 'app-links'} ${isPagination ? 'app-links-pagination' : 'app-links-list'}`}>
            {links.map((linkData) =>
                <AppLink
                    key={linkData.to}
                    linkData={linkData}
                    linkStyle={linkStyle}
                />
            )}
        </div>
    );
}

export default AppLinks;
export {
    LinkStyle
} from '../AppLink/AppLink';