// Dev Logs from November 2022
import { initialMatchTypes001, checklist } from '../../../LinkData/ExternalLinks';
import PageData from '../../PageData';

const november2022DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from November 25th 2022.',
        path: '/dev-log/2022-11-25',
        title: 'Dev Log',
        text: `Week of November 25th 2022: Began coding and art for runes and spells for the gameboard.
        Also got side tracked and ended up making a website to help manage my todos at home.`,
        links: [checklist],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from November 18th 2022.',
        path: '/dev-log/2022-11-18',
        title: 'Dev Log',
        text: `Week of November 18th 2022: Continued work on cleaning up initial game code and moving the code to be script controlled.`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from November 11th 2022.',
        path: '/dev-log/2022-11-11',
        title: 'Dev Log',
        text: `Week of November 11th 2022: Cleaned up initial game code and started moving the code to be script controlled.
        Took it a bit easy this week!`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from November 4th 2022.',
        path: '/dev-log/2022-11-04',
        title: 'Dev Log',
        text: `Week of November 4th 2022: Continued practicing pixel art, made some initial designs. Began work on initial logic
        for Rogue Match Up (tentatively named). The video below shows (with no sound) the idea of multiple matching types.`,
        links: [initialMatchTypes001],
    },
];

export default november2022DevLogPages;