import PageData from '../PageData';
import { taylorTheTechTutor, patreonLink } from '../../LinkData/ExternalLinks';

const aboutOurEmployees: PageData = {
    className: '', // Override this in props
    description: `About our employees`,
    text: `Our employees at Rock Bottom Games L.L.C. are very important to us
    and the following pages are dedicated to them.`,
    path: '/about-us/employees',
    title: 'Our Employees',
    links: [],
};

const aboutOurCEO: PageData = {
    className: '', // Override this in props
    description: `About our CEO, Taylor`,
    text: `Our first employee, our CEO, Taylor is a very humble person, he
    prefers you to continue forward and read more about the other, more integral
    employees to his company.`,
    path: '/about-us/ceo',
    title: 'CEO',
    links: [],
};

const aboutOurLeadProgrammer: PageData = {
    className: '', // Override this in props
    description: `About our lead programmer, Taylor`,
    text: `Our lead programmer, Taylor, has been programming since high school,
    he has had experience in multiple types of companies from startups to giant global companies.
    He actually started as a web developer and is now writing code for our games and our websites!`,
    path: '/about-us/lead-programmer',
    title: 'Programming',
    links: [],
};

const aboutOurLeadArtist: PageData = {
    className: '', // Override this in props
    description: `About our lead artist, Taylor`,
    text: `Our lead artist, Taylor, has been doing art mostly as a hobby, but now
    is learning to make actual art for games.  He is working hard at defining his craft,
    and we love what he does!`,
    path: '/about-us/lead-artist',
    title: 'Art',
    links: [],
};

const aboutOurOutreach: PageData = {
    className: '', // Override this in props
    description: `About our outreach program, Taylor the Tech Tutor`,
    text: `Our outreach department is comprised of Taylor, he will be doing
    outreach under the name of Taylor the Tech Tutor. He will be providing tutoring
    services for people who want to learn programming!`,
    path: '/about-us/outreach',
    title: 'Outreach',
    links: [taylorTheTechTutor],
}

const aboutOurMusicComposer: PageData = {
    className: '', // Override this in props
    description: `About our music composer, Taylor`,
    text: `Our music composer, Taylor, will be browsing royalty free music sites
    to find the best fitting songs for our games.  He will be sure to credit the authors!`,
    path: '/about-us/music-composer',
    title: 'Music',
    links: [],
};

const aboutYou: PageData = {
    className: '', // Override this in props
    description: `About our favorite supporter, you`,
    text: `I can't believe you have continued reading this far, you are by far my
    favorite type of supporter! This is also an ad break for my patreon...`,
    path: '/about-you',
    title: 'You!',
    links: [patreonLink],
};

const aboutOurSocialMediaManager: PageData = {
    className: '', // Override this in props
    description: `About our social media manager, Taylor`,
    text: `I can't believe you want to keep going, okay then! Well, our social media manager is
    also Taylor, he probably has forgotten to post updates because he's a bit busy...`,
    path: '/about-us/social-media',
    title: 'Social Media',
    links: [],
};

const aboutOurSupportTeam: PageData = {
    className: '', // Override this in props
    description: `About our support team, Taylor`,
    text: `Let's see... I guess, our support team... whenever anyone needs support? is also Taylor.`,
    path: '/about-us/support',
    title: 'Support',
    links: [],
};

const aboutOurIT: PageData = {
    className: '', // Override this in props
    description: `About our IT Department, Taylor`,
    text: `If Taylor ever needs IT support, he tries turning it off and on again.`,
    path: '/about-us/technical-issues',
    title: 'IT',
    links: [],
};

const aboutTime: PageData = {
    className: '', // Override this in props
    description: `About your time, are you wasting it?`,
    text: `Are you wasting it? Honestly, it's hard to come up with more things to say, isn't this a waste of your time?? What do you do all day??`,
    path: '/about-time',
    title: 'Time',
    links: [],
};

const aboutTime2: PageData = {
    className: '', // Override this in props
    description: `About your time, you are not wasting it.`,
    text: `Actually, what am I saying?? I love that you are here spending your time with me. You are definitely not wasting it.`,
    path: '/about-time-revised',
    title: 'Time',
    links: [],
};

const neverLeaveMe: PageData = {
    className: '', // Override this in props
    description: `Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    text: `Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    path: '/never-leave-me',
    title: 'Stay with me forever',
    links: [],
};

const pleaseStay: PageData = {
    className: '', // Override this in props
    description: `Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    text: `Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    path: '/please-stay',
    title: 'Where are you going',
    links: [],
};

const dontGo: PageData = {
    className: '', // Override this in props
    description: `Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    text: `Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me. Don't leave me.`,
    path: '/dont-go',
    title: 'Don\'t go!',
    links: [],
};

neverLeaveMe.prev = dontGo;
neverLeaveMe.next = pleaseStay;
pleaseStay.prev = neverLeaveMe;
pleaseStay.next = dontGo;
dontGo.prev = pleaseStay;
dontGo.next = neverLeaveMe;

const aboutAllOurEmployees: PageData[] = [
    aboutOurEmployees,
    aboutOurCEO,
    aboutOurLeadProgrammer,
    aboutOurLeadArtist,
    aboutOurOutreach,
    aboutOurMusicComposer,
    aboutYou,
    aboutOurSocialMediaManager,
    aboutOurSupportTeam,
    aboutOurIT,
    aboutTime,
    aboutTime2,
    neverLeaveMe,
    pleaseStay,
    dontGo
]

export default aboutAllOurEmployees;
export {
    aboutOurEmployees,
    aboutOurCEO,
    aboutOurLeadProgrammer,
    aboutOurLeadArtist,
    aboutOurOutreach,
    aboutOurMusicComposer,
    aboutYou,
    aboutOurSocialMediaManager,
    aboutOurSupportTeam,
    aboutOurIT,
    aboutTime,
    aboutTime2,
    neverLeaveMe,
    pleaseStay,
    dontGo
}