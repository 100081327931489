import React from 'react';
import PageData from '../../../Shared/Data/PageData/PageData';
import MetalLink from '../../../Components/Links/MetalLink/MetalLink';
import './InfoPanel.scss';


type InfoPanelProps = {
    readonly pageData: PageData,
    readonly className?: string | undefined
}

function InfoPanel({ className, pageData: { title, text, element, links } }: InfoPanelProps) {
    return (
        <div className={`app-info-panel ${className}`}>
            <h1>{title}</h1>
            <div className="scrollable-content">
                {text != null &&
                    <p>{text}</p>
                }
                {element != null &&
                    element
                }
                {links.length > 0 &&
                    <p>
                        {links.map((linkData) => (
                            <div className="info-link">
                                <MetalLink
                                    key={linkData.label}
                                    {...linkData}
                                />
                            </div>
                        ))}
                    </p>
                }
                {text == null && element == null && links.length === 0 &&
                    <p>Coming soon.</p>
                }
            </div>
        </div>
    );
}

export default InfoPanel;
