import React from 'react';
import title from './Images/Title.png';
import './Title.scss';

function Title() {
  return (
    <header className="title">
      <img src={title} alt="rock bottom games" />
    </header>
  );
}

export default Title;
