import React from 'react';
import Fab from '@mui/material/Fab';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import LinkIcon from '@mui/icons-material/Link';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Copyright from '../Copyright';
import './Footer.scss';

function Footer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const toggleDrawer =
        (newOpen: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setOpen(newOpen);
            };

    const list = () => (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Typography variant="subtitle1" sx={{ margin: 2 }}>
                Rock Bottom Games L.L.C. Quick Links:
            </Typography>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://linktr.ee/rockbottomgames">
                        <ListItemIcon>
                            <LinkIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Link Tree"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://youtube.com/@rockbottomgamesllc">
                        <ListItemIcon>
                            <YouTubeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"YouTube"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <Typography variant="subtitle1" sx={{ margin: 2 }}>
                Taylor the Tech Tutor Quick Links:
            </Typography>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://taylorthe.tech/tutor">
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Website"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://youtube.com/@taylorthetechtutor">
                        <ListItemIcon>
                            <YouTubeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"YouTube"} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="https://twitch.com/taylorthetechtutor">
                        <ListItemIcon>
                            <LiveTvIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Twitch"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Copyright />
        </Box>
    );

    return (
        <div className="site-nav-footer">
            <Box className="site-nav" sx={{
                margin: 1,
                position: 'absolute',
                bottom: 0,
                right: 0,
            }}>
                <Zoom
                    in={!open}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${!open ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >
                    <Fab color="primary" aria-label="add" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </Fab>
                </Zoom>
            </Box>
            <Drawer
                anchor={'bottom'}
                open={open}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </div>
    );
}

export default Footer;
