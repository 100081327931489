// Dev Logs from February 2023
import { taylorTheTechTutor } from '../../../LinkData/ExternalLinks';
import PageData from '../../PageData';

const february2023DevLogPages: PageData[] = [
    {
        className: '', // Override this in props
        description: 'Our weekly update from February 24th 2023.',
        path: '/tutor-log/2023-02-24',
        title: 'Tutor Log',
        text: `Week of February 24th 2023: Continued work on Taylor the tech tutor website and made icons and banners.`,
        links: [taylorTheTechTutor],
    },
    {
        className: '', // Override this in props
        description: 'Our weekly update from February 17th 2023.',
        path: '/tutor-log/2023-02-17',
        title: 'Tutor Log',
        text: `Week of February 17th 2023: Started planning out what I will need for tutoring.
        Also began thinking about characters for game assets with tutoring.`,
        links: [taylorTheTechTutor],
    },
    {
        className: '', // Override this in props
        description: 'Our special update from February 10th 2023.',
        path: '/wedding-log/2023-02-10',
        title: 'Dev Log',
        text: `Week of February 10th 2023: We had a wonderful wedding party and bachelor/bachelorette parties beforehand!`,
        links: [],
    },
    {
        className: '', // Override this in props
        description: 'Our special update from February 3rd 2023.',
        path: '/wedding-log/2023-02-03',
        title: 'Wedding Log',
        text: `Week of February 3rd 2023: Last week before we celebrate our wedding!  Focusing on that!`,
        links: [],
    },
];

export default february2023DevLogPages;