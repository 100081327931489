import React, { useState, useEffect } from 'react';
import AppSpacer from '../../AppSpacer/AppSpacer';
import AppLinks, { LinkStyle } from '../../Links/AppLinks/AppLinks';
import InfoPanel from '../../InfoPanels/InfoPanel/InfoPanel';
import PageData from '../../../Shared/Data/PageData/PageData';
import LinkData, { homeLink, getInternalLinkDataFromPageData } from '../../../Shared/Data/LinkData';
import { useLocation } from "react-router-dom";
import { LinkStateData } from '../../Links/CustomLink/CustomLink';
import './InfoPanelPage.scss';

function InfoPanelPage(pageData: PageData) {
    let location = useLocation();
    const [isOut, setIsOut] = useState(false);

    useEffect(() => {
        console.log('location path ' + location.pathname);
        console.log('pagedata path ' + pageData.path);
        const stateData: LinkStateData = location.state;
        console.log('statedata path ' + stateData.lastPage.pathname);
        const currentComponentPath = pageData.path;
        const lastPath = stateData.lastPage.pathname;
        const locationPath = location.pathname;
        // Before navigate:
        if (currentComponentPath === lastPath) {
            setIsOut(pageData.next !== undefined && pageData.next.path === locationPath);
            return;
        }
        // After navigate:
        setIsOut(pageData.next !== undefined && pageData.next.path === lastPath);

    }, [location, pageData]);

    const newHomeLink: LinkData = {
        to: homeLink.to,
        className: homeLink.className,
        containerClassName: 'app-link-home',
        target: homeLink.target,
        rel: homeLink.rel,
        ariaLabel: homeLink.ariaLabel,
        label: homeLink.label,
    }
    let links = [
        newHomeLink,
    ];

    if (pageData.next !== undefined) {
        links = [...links, getInternalLinkDataFromPageData(pageData.next, "app-link-next", ">")];
    }

    if (pageData.prev !== undefined) {
        links = [getInternalLinkDataFromPageData(pageData.prev, "app-link-prev", "<"), ...links];
    }

    return (
        <div className={isOut ? 'info-panel-page-out' : 'info-panel-page'}>
            <div className="info-panel-page-centered-container">
                <AppSpacer height={2} />
                <div className="info-panel-page-content">
                    <InfoPanel pageData={pageData} />
                </div>
                <AppSpacer height={1} />
                <AppLinks
                    isShort={true}
                    isPagination={true}
                    links={links}
                    linkStyle={LinkStyle.Metal}
                />
            </div>
        </div>
    );
}

export default InfoPanelPage;
